"use client";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Offcanvas from "react-bootstrap/Offcanvas";
// import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import Image from "next/image";
import OffcanvasClose from "../../../../public/assets/images/offcanvas-close.svg";

const NEW_MENU_SLUGS = ['services', 'industries']

const Header = () => {
  const router = useRouter();
  const slug = router?.query?.slug;
  const [subMenuClass, setSubMenuClass] = useState("");
  const [childLinkData, setChildLinkData] = useState();
  const [resourceData, setResourceData] = useState();
  const [title, setTitle] = useState();
  const [menuTitle, setMenuTitle] = useState();
  const [isNewMenu, setIsNewMenu] = useState();
  const [submenuData, setSubmenuData] = useState({
    description: "",
    image: "",
  });

  const headerFooterData = useSelector(
    (state) => state.headerFooterData.headerFooterDataList
  );

  const [headerData, setHeaderData] = useState();

  useEffect(() => {
    const fetchHeaderData = async () => {
      try {
        const response = await axios.get(
          process.env.NEXT_PUBLIC_API_ENDPOINT +
            "/cms/pages/?child_of=36&show_in_menus=true"
        );
        const data = response?.data && response?.data?.results;
        data && setHeaderData(data);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchHeaderData();
  }, []);

  const [sticky, setSticky] = useState("");

  // on render, set listener

  // header Sticky
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass =
      scrollTop >= 150 ? "fixed-top slideInDown animated" : "";
    setSticky(stickyClass);
  };

  const headersticky = `header ${sticky}`;
  // End header Sticky

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // closes submenu
  useEffect(() => {
    setSubMenuClass("d-none");
    setTimeout(() => {
      setSubMenuClass("");
    }, 50);
  }, [slug]);

  const [pageURL, setPageURL] = useState(0);
  useEffect(() => {
    setPageURL(window.location.pathname);
  });

  useEffect(() => {
    console.clear();
  }, []);

  return (
    <header
      className={`${headersticky} ${
        pageURL === "/careers" ? "transparent-sticky-header" : ""
      }`}
    >
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <div className="main-navbar">
            <div className="logo">
              <Link className="navbar-brand" href="/" itemProp="logo">
                <Image
                  width={500}
                  height={500}
                  src="/quixom-logo.svg"
                  alt="Quixom logo"
                  loading="eager"
                  priority
                />
              </Link>
            </div>
            <div className="navbar-right">
              <button
                className="navbar-toggler"
                type="button"
                onClick={handleShow}
                aria-label="menu"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              {["end"].map((placement, idx) => (
                <Offcanvas
                  key={idx}
                  show={show}
                  onHide={handleClose}
                  placement={placement}
                  name={placement}
                  responsive="lg"
                  className="mobile-Offcanvas"
                >
                  <div className="offcanvas-header">
                    <div className="offcanvas-title" id="offcanvasNavbarLabel">
                      <Link className="navbar-brand" href="#" itemProp="logo">
                        <Image
                          width={500}
                          height={500}
                          src={
                            headerFooterData &&
                            headerFooterData.site_configuration &&
                            headerFooterData?.site_configuration[0]?.logo?.url
                          }
                          alt={
                            headerFooterData &&
                            headerFooterData.site_configuration &&
                            headerFooterData?.site_configuration[0]?.logo?.alt_text
                          }
                          priority
                          loading="eager"
                        />
                      </Link>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      onClick={handleClose}
                    >
                      <Image
                        src={OffcanvasClose}
                        alt="offcanvas-close"
                        height={100}
                        width={100}
                        loading="eager"
                      />
                    </button>
                  </div>
                  <div className="offcanvas-body">
                    <ul className="navbar-nav web-navbar justify-content-end flex-grow-1">
                      {headerData?.map((val, index) => (
                        <React.Fragment key={index}>
                          <li
                            className="nav-item"
                            onMouseEnter={() => {
                              setIsNewMenu(NEW_MENU_SLUGS.includes(val?.meta?.slug))
                              setMenuTitle(val?.title);
                              setChildLinkData(val?.child);
                              setTitle(val?.meta?.slug);
                              setResourceData(val?.detail);
                              if (subMenuClass.length) {
                                setSubMenuClass("");
                              }
                            }}
                          >
                            {
                              (title == "industries" ||
                              title == "resource" ||
                              title == "services" ||
                              title == "Contact Us" ||
                              title == "product-development" ? (
                                <span className="nav-link" role="button">
                                  {val?.title}
                                </span>
                              ) : (
                                <Link
                                  className="nav-link"
                                  aria-current="page"
                                  href={val?.url}
                                  itemProp="menu"
                                >
                                  {val?.title}
                                </Link>
                              ))}

                            {childLinkData && childLinkData.length > 0 && (
                              <React.Fragment>
                                <div className={`submenu ${isNewMenu && 'submenu-services'} ${subMenuClass}`}>
                                  <div className="submenu-inner">
                                    <div className="container">
                                      <div className="row">
                                        <div className={isNewMenu ? "col-lg-12" : "col-lg-6"}>
                                          <div className="submenu-left-inner">
                                            <div className="submenu-heading">
                                              <p
                                                itemProp="heading"
                                                className="sub-menu-title"
                                              >
                                                {menuTitle}
                                              </p>
                                            </div>
                                            <div className="sub-menu-content">
                                              <ul>
                                                {childLinkData?.map(
                                                  (val, index) => (
                                                    <li
                                                      key={index}
                                                      onMouseEnter={() =>
                                                        setSubmenuData({
                                                          description:
                                                            val?.description,
                                                          image:
                                                            val?.image?.url,
                                                        })
                                                      }
                                                    >
                                                      <Link
                                                        className="sub-menu-link"
                                                        href={
                                                          (title ===
                                                            "industries" &&
                                                            `/industries/${val?.meta?.slug}`) ||
                                                          (title ===
                                                            "services" &&
                                                            `/services/${val?.meta?.slug}`) ||
                                                          (title ===
                                                            "product-development" &&
                                                            `/services/${val?.meta?.slug}`)
                                                        }
                                                      >
                                                        {val?.title}
                                                      </Link>
                                                    </li>
  
                                               

                                                  )
                                                )}
                                                {title === "services" && (
                                                  <li>
                                                    <Link
                                                      className="sub-menu-link all-service-link"
                                                      href={`/services`}
                                                    >
                                                      View All Services
                                                    </Link>
                                                  </li>
                                                )}
                                              </ul>
                                            </div>
                                            {title === "services" && (
                                              <div className="submenu-bottom-link">
                                                <Link
                                                  href="/services"
                                                  className="btn btn-link"
                                                >
                                                  All Services
                                                  <span className="btn-link-arrow"></span>
                                                </Link>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        {!isNewMenu && (
                                        <div className="col-lg-6">
                                          <div className="submenu-right-inner">
                                            <div className="submenu-card">
                                              <div className="submenu-card-thumbnail">
                                                <Image
                                                  src={
                                                    submenuData?.image ||
                                                    childLinkData[0]?.image?.url
                                                  }
                                                  alt={childLinkData[0]?.image?.alt_text ?? 'Services'}
                                                  height={500}
                                                  width={500}
                                                  loading="eager"
                                                  style={{
                                                    height: "auto",
                                                    width: "auto",
                                                  }}
                                                />
                                              </div>
                                              <div className="submenu-card-content">
                                                <p
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      submenuData?.description ||
                                                      childLinkData[0]
                                                        ?.description,
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                            <div className="submenu-btn-grup">
                                              {headerFooterData &&
                                                headerFooterData?.navbar_submenu &&
                                                headerFooterData?.navbar_submenu[0]?.menus?.map(
                                                  (val) => (
                                                    <div
                                                      key={val?.id}
                                                      className="submenu-btn"
                                                    >
                                                      <Link
                                                        className="btn btn-secondary"
                                                        href={
                                                          val?.value?.page?.url
                                                        }
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            val?.value?.title,
                                                        }}
                                                      ></Link>
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </div>)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}

                            {title === "resource" && (
                              <div className="submenu">
                                <div className="submenu-inner">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-lg-4">
                                        <div className="submenu-left-inner">
                                          <div className="submenu-heading">
                                            <p
                                              itemProp="heading"
                                              className="sub-menu-title"
                                            >
                                              {val?.title}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-8 submenu-right-col">
                                        <div className="submenu-right-inner">
                                          <div className="submenu-blog-div">
                                            <div className="row">
                                              {resourceData?.map(
                                                (val, index) => {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className="col-md-6"
                                                    >
                                                      <Link
                                                        href={
                                                          val?.value[0]?.value
                                                            ?.page?.url
                                                        }
                                                        className="submenu-blog-link"
                                                      >
                                                        <div className="submenu-blog-card">
                                                          <div className="submenu-blog-thumbnail">
                                                            <Image
                                                              src={
                                                                val?.value[0]
                                                                  ?.value?.image
                                                                  ?.url
                                                              }
                                                              alt={val?.value[0]
                                                                ?.value?.image
                                                                ?.alt_text ?? 'Services'}
                                                              height={500}
                                                              width={500}
                                                              loading="eager"
                                                            />
                                                          </div>
                                                          <div className="submenu-blog-content">
                                                            <span>
                                                              {
                                                                val?.value[0]
                                                                  ?.value?.title
                                                              }
                                                            </span>
                                                            <p
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  val?.value[0]
                                                                    ?.value
                                                                    ?.description,
                                                              }}
                                                            ></p>
                                                          </div>
                                                        </div>
                                                      </Link>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                          <div className="submenu-btn-grup">
                                            {headerFooterData &&
                                              headerFooterData?.navbar_submenu &&
                                              headerFooterData?.navbar_submenu[0]?.menus?.map(
                                                (val) => (
                                                  <div
                                                    key={val?.id}
                                                    className="submenu-btn"
                                                  >
                                                    <Link
                                                      className="btn btn-secondary"
                                                      href={
                                                        val?.value?.page?.url
                                                      }
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          val?.value?.title,
                                                      }}
                                                    ></Link>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </li>
                        </React.Fragment>
                      ))}
                    </ul>

                    <div className="mobile-menu">
                      <div className="mobile-menu-inner">
                        <Accordion>
                          {headerData?.map((val, index) => {
                            return (
                             
                              <Accordion.Item key={index} eventKey={index}>
                                 { (val?.title == 'About Us' || val?.title == 'Careers' || val?.title == 'Contact Us')  &&
                                    <Accordion.Button
                                      onMouseEnter={() => {
                                        setChildLinkData(val?.child);
                                        setTitle(val?.meta?.slug);
                                      }}
                                      className="no-child"
                                    >
                                      <Link
                                        href={
                                          title == "industries" ||
                                          title == "services" ||
                                          title == "resource" ||
                                          title == "product-development"
                                            ? ""
                                            : val?.url
                                        }
                                      >
                                        {val?.title} 
                                      </Link>
                                    </Accordion.Button>
                                 } 
                                 { (val?.title != 'About Us' && val?.title != 'Careers' && val?.title != 'Contact Us')  &&
                                    <Accordion.Button
                                      onMouseEnter={() => {
                                        setChildLinkData(val?.child);
                                        setTitle(val?.meta?.slug);
                                      }}
                                    >
                                      <Link
                                        href={
                                          title == "industries" ||
                                          title == "services" ||
                                          title == "resource" ||
                                          title == "product-development"
                                            ? ""
                                            : val?.url
                                        }
                                      >
                                        {val?.title} 
                                      </Link>
                                    </Accordion.Button>
                                 } 
                                <Accordion.Body>
                                  {childLinkData &&
                                    childLinkData.length > 0 && (
                                      <div className="mobile-sub-navbar">
                                        <ul className="mobile-sub-menu">
                                          {childLinkData?.map((val, index) => {
                                            return (
                                              <li key={index}>
                                                <Link
                                                  href={`/services/[val?.meta?.slug]`}
                                                  as={
                                                    (title === "industries" &&
                                                      `/industries/${val?.meta?.slug}`) ||
                                                    (title === "services" &&
                                                      `/services/${val?.meta?.slug}`) ||
                                                    (title ===
                                                      "product-development" &&
                                                      `/services/${val?.meta?.slug}`)
                                                  }
                                                  className="mobile-menu-link"
                                                  onClick={() => {
                                                    (title == "industries" &&
                                                      localStorage.setItem(
                                                        "industryAPI",
                                                        val?.meta?.slug
                                                      )) ||
                                                      (title == "services" &&
                                                        localStorage.setItem(
                                                          "serviceAPI",
                                                          val?.meta?.slug
                                                        ));
                                                  }}
                                                >
                                                  {val?.title}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    )}
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                        {/* <div className="mobile-menu-btn">
                            {headerFooterData &&
                              headerFooterData?.navbar_submenu[0]?.menus?.map(
                                (val, index) => (
                                  <Link
                                    key={index}
                                    className="btn btn-primary"
                                    href={val?.value?.page?.url}
                                    dangerouslySetInnerHTML={{
                                      __html: val?.value?.title,
                                    }}
                                  ></Link>
                                )
                              )}
                          </div> */}
                      </div>
                    </div>
                  </div>
                </Offcanvas>
              ))}


              <div key={1000} className="navbar-btn">
                <Link
                  href="/request-for-proposal-quote"
                  itemProp="link"
                  className="btn btn-primary btn-hover-6"
                >
                  Request For Proposal
                </Link>
              </div>
                 
            </div>
          </div>
        </div>
      </nav>
      <div className="menu-overlay"></div>
    </header>
  );
};

export default Header;
